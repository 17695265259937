import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const PaymentCard = () => {
    const navigate = useNavigate();

    const handleProceedToCheckout = () => {
        navigate('/checkout');
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>Payment</Card.Title>
                <Card.Text>
                    At Gabino ISL, we are committed to providing our customers with exceptional service and quality interior lighting solutions. To ensure a smooth transaction and delivery process, please note the following payment policy:
                    ... <Link to="/pages/payment-and-refund">Read More</Link>
                </Card.Text> 
                <Button variant="danger" onClick={handleProceedToCheckout} className="mt-2">
                    Proceed to Checkout
                </Button>
            </Card.Body>
        </Card>
    );
};

export default PaymentCard;
