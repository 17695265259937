import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Policy = () => {
  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h2>Gabino ISL Payment and Refund Policy</h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Payment Policy</Card.Title>
              <Card.Text>
                At Gabino ISL, we are committed to providing our customers with exceptional service and quality interior lighting solutions. To ensure a smooth transaction and delivery process, please note the following payment policy:
              </Card.Text>
              <ul>
                <li><strong>Payment Terms:</strong> Full payment is required before delivery. We accept payments through bank transfer, credit/debit cards, and online payment platforms.</li>
                <li><strong>Order Confirmation:</strong> Upon receipt of payment, an order confirmation will be sent to your registered email address detailing your purchase and expected delivery date.</li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Refund Policy</Card.Title>
              <Card.Text>
                We take pride in the quality of our products at Gabino ISL. However, we understand that there may be circumstances under which you need to request a refund:
              </Card.Text>
              <ul>
                <li><strong>Cancellation:</strong> Orders may be canceled within 24 hours of payment without any charges. After this period, a cancellation fee of 15% of the total order value will be applied.</li>
                <li><strong>Damaged Goods:</strong> It is non-refundable except if the fault is from the factory.</li>
                <li><strong>Return Process:</strong> To initiate a return, please contact our customer service team to obtain a Return Merchandise Authorization (RMA) number. Returns without an RMA number will not be accepted.</li>
              </ul>
              <p>
                Customized lighting solutions are made to order and are non-refundable. We encourage customers to review their custom orders carefully before finalizing payment.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Contact Us</Card.Title>
              <Card.Text>
                For any questions regarding our payment and refund policy, please contact our customer service team at <a href="mailto:gabinoisl01@gmail.com" >gabinoisl01@gmail.com</a> or give us a call at <a href="tel:+2349160000789">+2349160000789</a>.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Policy;
