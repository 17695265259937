


const Faqs = () => {
    return (
        <>

        </>
    )
}

export default Faqs;