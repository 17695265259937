import React from 'react';
import { useParams } from 'react-router-dom';
import Faqs from '../Layouts/Pages/Faqs';
import HelpCenter from '../Layouts/Pages/HelpCenter';
import Policy from '../Layouts/Pages/Policy';

const pagesMap = {
  'faqs': Faqs,
  'help-center': HelpCenter,
  'payment-and-refund': Policy,
};

const Pages = () => {
  const { page } = useParams();
  const PageComponent = pagesMap[page];
 
  return (
    <div>
      {PageComponent ? <PageComponent /> : <div>Page Not Found</div>}
    </div>
  );
};

export default Pages;
